<!-- Navigation -->
<treo-vertical-navigation class="treo-theme-light" [appearance]="'classic'" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [opened]="!isScreenSmall" style="background: white;">

    <div treoVerticalNavigationContentHeader>
        <div class="logo-guaya" [routerLink]="['/mapa']">
            <img [src]="'assets/guayaquiliando-icons/Imagen Guayaquiliando.svg'">
        </div>

        <div>
            <h2 style="color: #691446;  align-content: center; text-align: center;">Rutas</h2>
            <div class="rutas" *ngFor="let route of routes" [routerLink]="['/map-route/', route.id]" style="color: #691446;" (click)="clickRoute(route)">
                <p><img [src]="'assets/guayaquiliando-icons/16x16.png'" *ngIf="route.icon==true" style="width: 30px; position: absolute; margin-left: 10%;">{{route.name}}</p>
            </div>
            <div *ngIf="description != null">
                <h2 style="color: #F59f3f;  align-content: center; text-align: center;">Descripción</h2>
                <div class="description" >
                    <p style="text-align: justify;">{{description}}</p>
                </div>
            </div>
        </div>

    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper" >

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>
        <button class="info-buttons" mat-raised-button [routerLink]="['/information']">
            Nosotros
        </button>
        <button class="info-buttons" mat-raised-button [routerLink]="['/info-contact']">
            Contacto
        </button>
        <button class="info-buttons" mat-raised-button [routerLink]="['/investigacion']">
            Investigación
        </button>

        <div treoVerticalNavigationContentFooter>
            <div class="media">
                <a style="width: 64px; display: inline-block;" href="https://www.facebook.com/lienzosdeasfalto" target="_blank">
                    <img src="assets\guayaquiliando-icons\ICONOS REDES SOCIALES _Mesa de trabajo 1.svg">
                </a>
                <a style=" width: 64px; display: inline-block;" href="https://www.instagram.com/lienzosdeasfalto/?hl=es-la" target="_blank">
                    <img src="assets\guayaquiliando-icons\ICONOS REDES SOCIALES _Mesa de trabajo 1 copia.svg">
                </a>
            </div>
        </div>

        <!-- Spacer 
        <div class="spacer"></div>-->

        <!-- Search 
        <search [appearance]="'bar'"></search>-->

        <!-- Shortcuts
        <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->

        <!-- Messages
        <messages [messages]="data.messages"></messages> -->

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <div class="footer">
        <!-- <div class="logo-lienzos">
            <img src="assets\guayaquiliando-icons\asfalto_Mesa.png">
        </div>
        &nbsp;&nbsp;
        <div class="logo-malaga">
            <img src="\assets\guayaquiliando-icons\malaga1.png">
        </div>
        &nbsp;&nbsp;
        <div class="logo-gardeliana">
            <img src="assets\guayaquiliando-icons\Gardeliana.png">
        </div>
        &nbsp;&nbsp;
        <div class="logo-alcaldia">
            <img src="assets\guayaquiliando-icons\alcaldia_medellin.png">
        </div> -->
    </div>

</div>