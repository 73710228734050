<!-- Open button, 'bar' only -->
<button class="search-toggle-open"
        mat-icon-button
        *ngIf="appearance === 'bar' && !opened"
        (click)="open()">
    <mat-icon [svgIcon]="'search'"></mat-icon>
</button>

<!-- Search container -->
<div class="search-container"
     *ngIf="appearance === 'basic' || (appearance === 'bar' && opened)"
     [@.disabled]="appearance === 'basic'"
     @slideInTop
     @slideOutTop>

    <mat-form-field class="treo-mat-no-subscript search-input"
                    #searchInput>
        <mat-icon matPrefix
                  [svgIcon]="'search'"></mat-icon>
        <input matInput
               [formControl]="searchControl"
               [placeholder]="'Search for a page or a contact'"
               [matAutocomplete]="matAutocomplete"
               (keydown)="onKeydown($event)">
    </mat-form-field>

    <mat-autocomplete [class]="'search-results search-results-appearance-' + appearance"
                      #matAutocomplete="matAutocomplete"
                      [disableRipple]="true">

        <mat-option class="no-results"
                    *ngIf="results && !results.length">
            No results found!
        </mat-option>

        <mat-option *ngFor="let result of results"
                    [routerLink]="result.link">

            <!-- Page result -->
            <div class="result page-result"
                 *ngIf="result.resultType === 'page'">
                <div class="badge">Page</div>
                <div class="title">
                    <span [innerHTML]="result.title"></span>
                    <span class="link"
                          [routerLink]="result.link">{{result.link}}</span>
                </div>
            </div>

            <!-- Contact result -->
            <div class="result contact-result"
                 *ngIf="result.resultType === 'contact'">
                <div class="badge">Contact</div>
                <div class="title">
                    <span [innerHTML]="result.title"></span>
                </div>
                <div class="image">
                    <img *ngIf="result.avatar"
                         [src]="result.avatar">
                    <mat-icon *ngIf="!result.avatar"
                              [svgIcon]="'account_circle'"></mat-icon>
                </div>
            </div>

        </mat-option>

    </mat-autocomplete>

    <!-- Close button, 'bar' only -->
    <button class="search-toggle-close"
            mat-icon-button
            *ngIf="appearance === 'bar'"
            (click)="close()">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>

</div>
