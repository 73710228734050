// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    mapboxKey: 'pk.eyJ1IjoieWNhc3RyaWxsb24iLCJhIjoiY2tiNDhuaXE2MHV5ejJxbjlveTkyY3AyciJ9.jC3ePwpIy7SG_HhgqDz61w',
    firebaseConfig : {
        apiKey: "AIzaSyBqr8G6lKCrPyrsw2E3Y_a0qLgDOe-3VMQ",
        authDomain: "guayaquiliandopro.firebaseapp.com",
        databaseURL: "https://guayaquiliandopro.firebaseio.com",
        projectId: "guayaquiliandopro",
        storageBucket: "guayaquiliandopro.appspot.com",
        messagingSenderId: "27173249961",
        appId: "1:27173249961:web:c5e859f1c17eefe8fc9ab8",
        measurementId: "G-YK3P1NWD2S"
      },
      /* var admin = require("firebase-admin");

      var serviceAccount = require("path/to/serviceAccountKey.json");
      
      admin.initializeApp({
        credential: admin.credential.cert(serviceAccount),
        databaseURL: "https://guayaquiliando-4c832.firebaseio.com"
      }); */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
