import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { IGRoutes, IRoutes, IGSites, ISites } from '../../models/routes-interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  routesCollection: AngularFirestoreCollection<IRoutes>;
  routes: Observable<IGRoutes[]>;
  route: Observable<IGRoutes>;
  routesDoc: AngularFirestoreDocument<IGRoutes>;
  routesTest: AngularFirestore;
  sitesCollection: AngularFirestoreCollection<ISites>;
  site: Observable<ISites>;
  sites: Observable<ISites[]>;
  sitesDoc: AngularFirestoreDocument<IGSites>;
  sitesTest: AngularFirestore;


  constructor( public afs: AngularFirestore ) {
    //this.routes = afs.collection('routes').valueChanges();
   }
  
  getRoutes() {
     return this.routes;
   }

   getAllRoutes() {
    this.routesCollection = this.afs.collection<IGRoutes>('routes');
    return this.routes = this.routesCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as IGRoutes;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllSites() {
    this.sitesCollection = this.afs.collection<IGSites>('sites');
    return this.sites = this.sitesCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as IGSites;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getRoute(id: string){
    this.routesDoc = this.afs.doc<IGRoutes>(`routes/${id}`);
    return this.route = this.routesDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as IGRoutes;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  getSite(id: string){
    this.sitesDoc = this.afs.doc<IGSites>(`sites/${id}`);
    return this.site = this.sitesDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as IGSites;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addRoute(route: IRoutes): void {
    this.routesCollection.add(route);
  }

  addSite(site: ISites): void {
    this.sitesCollection.add(site);
  }

  deleteRoute(id: string): void {
    this.routesDoc = this.afs.doc<IGRoutes>(`routes/${id}`);
    this.routesDoc.delete();
  }

  deleteSite(id: string): void {
    this.sitesDoc = this.afs.doc<IGSites>(`sites/${id}`);
    this.sitesDoc.delete();
  }

  addRouteTest(route: any): void {
    this.routesCollection.add(route);
  }

  updateSite(id: string,site: any) {
    
    this.sitesCollection.doc(id).update(site);
  }

  updateRoute(id: string,route: any) {
    
    this.routesCollection.doc(id).update(route);
  }

  updateRouteTestPro() {
    let tt={text:'a', title:'jaja' };
    this.routesCollection.doc('/O5nVqKdp7yHL8Kh08D9F/sites/5lOkPSUCwMrzKgO93htE').update(tt);
  }

  addGeneral(){
    let tt={text:'esta es la prueba', title:'jaja' };
    return this.afs.collection('routes').add(tt);
  }


  getAllRoutesSimple(){
    return this.afs.collection('routes').snapshotChanges();
  }

  getAllSitesSimple(){
    return this.afs.collection('sites').snapshotChanges();
  }

  addGeneralEsp(){
    let tt={url:'http.dfsdfsdfsd' };
    return this.afs.collection('/sites/CVrdPf7gtJqr4Yc9VyzD/images').add(tt);
  }
  

}
