import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { RoutesService } from '../../../../services/routesService/routes.service';

interface IRoutes {
    id: any,
    name: string,
    description: string,
    icon: boolean

}

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    styleUrls    : ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    data: any;
    isScreenSmall: boolean;
    routes: Array<IRoutes>  = [];
    description: any = null;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {Router} _router
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        private routesService: RoutesService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit()
    {
        this.getListRoutes();
     // Subscribe to the resolved route data
         this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = true; //matchingAliases.includes('lt-md');
            });
            setTimeout(()=>{                           //<<<---using ()=> syntax
                this._treoMediaWatcherService.onMediaChange$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(({matchingAliases}) => {
    
                    // Check if the breakpoint is 'lt-md'
                    this.isScreenSmall = matchingAliases.includes('lt-md');
                });
           }, 6000); 
    }


    getListRoutes() {
        this.routes = [];
        this.routesService.getAllRoutes()
          .subscribe(data => {
            data.forEach(element => {
                let item: IRoutes = {
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    icon: false
                }
                this.routes.push(item);
            });
          });
      }

    clickRoute(route){
        this.routes.forEach(element => {
            element.icon = false;
            if (route.id === element.id){
                element.icon = true;
            }
        });
        this.description = route.description;
    }
      
    
        
      
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void
    {
        // Get the navigation
        let  navigation = this._treoNavigationService.getComponent(key);


        if ( navigation )
        {
            console.log(navigation);
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
